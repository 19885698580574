<template>
  <main-template>
    <secondary-pages-background />
    <div class="p-auth p-other">
      <div class="container-other">
        <div class="wrapper">
          <h1 class="section-title ta--center">
            Вход в личный кабинет
          </h1>
          <div class="divider" />
          <p class="mb-l ta--center">
            Вы можете войти используя имеющиеся логин и пароль если являетесь пользователем или партнером компании CLICK
          </p>
          <form class="form-auth mb-l">
            <input-text
              v-model="login"
              class="mb-s"
              placeholder="Логин"
              required
            />
            <input-text
              v-model="password"
              class="mb-l"
              type="password"
              placeholder="Пароль"
              required
            />
            <app-button
              class="btn-submit mb-m"
              title="Войти"
              description="Вход в личный кабинет для пользователей и партнеров"
              primary
            >
              <icon-arrow-right class="icon" />
            </app-button>
            <app-link to="/">
              Забыли пароль?
            </app-link>
          </form>
          <p class="required-message fs--small">
            <span class="required">*</span> - Обязательные поля
          </p>
        </div>
      </div>
    </div>
  </main-template>
</template>

<script>
import SecondaryPagesBackground from '@/components/SecondaryPagesBackground'
import MainTemplate from '@/layouts/MainTemplate'
import AppButton from '@/components/common/AppButton'
import AppLink from '@/components/common/AppLink'
import IconArrowRight from '@/assets/img/icons/arrow-right.svg'
import InputText from '@/components/input/InputText'

export default {
  name: 'Auth',
  components: {
    SecondaryPagesBackground,
    MainTemplate,
    AppButton,
    AppLink,
    IconArrowRight,
    InputText
  },
  data () {
    return {
      login: '',
      password: ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .p-auth {
    .container-other {
      .wrapper {
        max-width: 640px;
        .form-auth {
          display: flex;
          flex-direction: column;
          .btn-submit {
            .icon {
              path {
                stroke: $white;
              }
            }
          }
        }
        .required-message {
          font-weight: 300;
          .required {
            color: #FF5064;
          }
        }
      }
    }
  }
</style>
